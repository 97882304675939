import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SIDEBAR_BREAKPOINT } from '../constants/layout';
import * as colors from '../util/colors';

export const MultiColumnWrapper = styled.div`
    background-image: linear-gradient(to right, ${colors.cloud} 0%, ${colors.cloud} 50%, ${colors.mist} calc(50% + 1px), ${colors.mist} 100%);
    
    > div {
        display: grid;
        //yes, this weird format is correct: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas
        grid-template-areas: "main"
                            "sidebar";
        max-width: 1032px;
        margin: 0 auto;
        

        > *:last-child{
            background: ${colors.mist}
        }

        @media (min-width: ${SIDEBAR_BREAKPOINT}) {
            grid-template-columns: 1fr 252px;
            grid-template-areas: "main sidebar";
        }
    }
    
`;

export const TemplateColumnWithSidebarContainer = (props) => {
  return (
    <MultiColumnWrapper>
      <div>
        {props.children}
      </div>
    </MultiColumnWrapper>
  )
}

const TemplateColumn = props => {
  const maxWidth = props.maxWidth;
 
  useEffect(() => {
    const rightTrail = document.querySelector('[data-template-column="template-e-sidebar"] > section');
 
    const handleResize = () => {
 
      if (rightTrail) {
        const isTall = window.innerHeight < (rightTrail.getBoundingClientRect().height + 70);
        if (isTall) {
          rightTrail.style.position = 'unset';
        } else {
          rightTrail.style.position = 'sticky';
        }
      }
    };
 
    const handleScroll = () => {
      if (rightTrail && rightTrail.style.position == 'sticky' && window.innerWidth > 593) {
        const sidebar = document.querySelector('[data-template-column="template-e-main"] > section');
        const scrollValue = sidebar?.offsetTop;
 
        if (sidebar && rightTrail) {          
          if (document.querySelector('.headroom').classList.contains('headroom--pinned') && window.scrollY >= scrollValue - 5) {
            rightTrail.style.top = '80px';
          } else {
            rightTrail.style.top = '0px';
          }
        }
      }
    };
 
    handleResize();
 
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
 
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const children = React.Children.map(props.children, (child) => {

    return React.cloneElement(child, {
      maxWidth: (maxWidth) ? maxWidth : 992,
    });
  });
  return <div data-template-column={`${props.id}`}>{children}</div>;
};

export default TemplateColumn;
