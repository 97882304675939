import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import TemplateColumn from '../layouts/TemplateColumn';
import { IndicationsList, PageHeader, PageSection, RelatedContent, PrimaryCallOut } from '../components';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Approved Indications for KEYTRUDA® (pembrolizumab) | Health Care Professionals`,
    keywords: `keytruda indications`,
    description: `KEYTRUDA® (pembrolizumab) is approved for certain types of cancers. Health care professionals may find indication information including clinical data.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/approved-indications/","@type":"MedicalWebPage","name":"Selected Indications for KEYTRUDA® (pembrolizumab)","description":"Before prescribing KEYTRUDA® (pembrolizumab), please read the accompanying Prescribing Information. The Medication Guide also is available."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/approved-indications/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}
const jobCode = jobCodes[0].jobCode;

const relatedContent = [
    {
        section: 'Efficacy',
        link: {
            url: '/efficacy/',
            label: 'Efficacy'
        }
    },
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Resources',
        link: {
            url: '/safety/monitoring-managing-adverse-reactions/',
            label: 'Treatment Monitoring & Management '
        }
    }
];

const Page = ({ location }) => {

    return (
        <DefaultLayout pageMeta={pageMeta} location={location} jobCode={jobCode}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="Selected Indications for KEYTRUDA® (pembrolizumab)" />
                    <PageSection bgColor="cloud">
                        <IndicationsList columns={2}/>
                    </PageSection>
                    <PageSection>
                        <PrimaryCallOut
                            title="Not seeing the type of tumor you are looking for?"
                            content="For more information, including a complete list of indications KEYTRUDA is approved to treat, please see the full Prescribing Information for KEYTRUDA."
                            secondaryAction={{ label: 'View Prescribing Information', href: '/prescribing-information/', external: true, target: '_blank' }}
                        />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
